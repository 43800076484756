import React, { Component } from 'react'
import { colors, spacing } from '../constants/Constants.js'
import Ishaan from '../images/meIJ.jpg'
import numeracy from '../images/numeracy-example.png'
import "../styles/IJ.css";


const tarS = '844', tarV = '175', tarH = '56';
var mLeft = '0%';
var divL = '12%';
var divR = '9%';

const GitHub = { background: '#222', color: 'white' }
const Other = { background: '#777', color: 'white' }
const Screen = { background: '#1499ff', color: 'white' }
// blue below purple: a02de3
// const Screen = { background: '#1499ff', color: 'white' }
const Presentation = { background: '#d3522f', color: 'white' }
const Play = { background: '#27a131', color: 'white' }
const Devpost = { background: '#0087b5', color: 'white' }
const Paper = { background: '#a02de3', color: 'white' }
const Poster = { background: '#8B4513', color: 'white' }
const spacer = '15px'
const descriptionSpacingTop = '-7px'

export class Projects extends Component {
    projectsRef = React.createRef();

    componentDidMount() {
        this.scrollToHash();
    }

    componentDidUpdate() {
        this.scrollToHash();
    }

    scrollToHash() {
        const hash = window.location.hash;
        if (hash && this.projectsRef.current) {
            const element = document.querySelector(hash);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView();
                    window.scrollBy(0, -100); // Scroll back up by 150px
                }, 100);
            }
        }
    }

    render() {
        return (
            <div style={divStyle} id="projectsScroll">
                <p style={{
                    fontSize: '34px',
                    fontWeight: '750',
                    // marginTop: '10px', BEFORE Design bar
                    marginTop: '15px',
                    color: 'black', userSelect: 'none',
                    textAlign: 'center', marginBottom: '0px'
                }}>Projects</p>
                <p style={{ textAlign: 'center', marginTop: '20px', marginBottom: '28px' }}>
                    Below are details on some of my projects. 40+ more can be found on my <span onClick={() => window.open("https://github.com/ishaanjav")} style={{ cursor: 'pointer', color: '#000', fontWeight: '700', textDecoration: 'underline' }} >GitHub</span>.
                </p>

                {/* RL Agent for Playing Terminal AI Game */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, }}>
                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Globally-Ranked RL Agent for Playing Terminal's Tower-Defense Game</u> ⭐</h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>Feb - May 2024:</span> Top 1% Algo Globally</b>, <b><span style={{ fontSize: '15.5px', fontStyle: 'italic', color: '#4d75a3' }}><b>(COS 435 RL Final Project)</b></span></b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%', marginBottom: '-11px'
                        }}>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://www.youtube.com/embed/CyHIvS6qMIc")}>Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Paper }} onClick={() => window.open("https://ishaanjavali.me/terminal-rl")}>Paper</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Poster }} onClick={() => window.open("https://ishaanjavali.me/terminal-rl-poster")}>Poster</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://ishaanjavali.me/terminal-rl-slides")}>Slides</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            <p>
                                We developed a novel <b>Reinforcement Learning (RL) agent</b> to compete in Correlation One's Terminal Competition, a strategic tower-defense game.
                                Our algorithm ranked in the <b>top 1% of over 100,000 competing algorithms</b> globally.
                            </p>

                            <p style={{ marginTop: '-10px' }}>
                                Unlike  the other algorithms competing in the game (which are rule-based), we designed and deployed a first-of-its-kind <b>deep learning approach</b>, using a CNN to learn and encode the spatial structure of the game board
                                followed by an MLP to output a distribution over 714 possible actions.
                            </p>

                            <p style={{ marginTop: '-10px' }}>
                                We scraped and processed 80,000+ matches from past competitions, compiling a comprehensive dataset which we used to train our model via <b>behavior cloning</b>.
                                <b>
                                    <span style={{ color: 'red' }}> Check out our video on the right!</span>
                                </b>
                            </p>
                        </p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center', marginTop: '-12px' }}>
                        <br />
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%"
                                src="https://www.youtube.com/embed/CyHIvS6qMIc"
                                allowfullscreen allow="autoplay">
                            </iframe>
                        </div>
                    </div>
                </div>

                {/* Probing LLM's Numerical Commonsense Knowledge */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, flex: '0 1 74%' }}>
                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Probing LLM's Numerical Commonsense Knowledge</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>March - May 2024</span></b> <b><span style={{ fontSize: '15.5px', fontStyle: 'italic', color: '#4d75a3' }}>(COS 484 NLP Final Project)</span></b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%', marginBottom: '0px'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/LLM-Numeracy-Evaluation")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Paper }} onClick={() => window.open("https://ishaanjavali.me/LLM%20Numeracy%20Paper.pdf")}>Paper</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Poster }} onClick={() => window.open("https://ishaanjavali.me/LLM%20Numeracy%20Poster.pdf")}>Poster</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45',
                            marginBottom: '0px', marginTop: '-19px'
                        }}>
                            <p>
                                We evaluated the <b>numerical commonsense knowledge</b> of BERT and RoBERTa LLMs on <b>masked-word prediction tasks</b>, introducing
                                new adversarial probes and model ablations: adding part-of-speech tagging to inputs, creating a new Guesstimation task,
                                and probes involving inflating the number of numeric words and nouns in inputs.
                            </p>

                            <p style={{ marginTop: '-10px' }}>
                                We both <b>post-trained the LLMs</b> on our new datasets and conducted <b>zero-shot learning evaluations</b>,
                                extensively <b>assessing model interpretability</b> by visualizing and examining their attention masks on select inputs.
                            </p>

                            <p style={{ marginTop: '-10px' }}>
                                Check out our <a href="https://ishaanjavali.me/LLM%20Numeracy%20Paper.pdf" style={{ color: 'black', fontWeight: 600, textDecoration: 'underline' }}>paper</a> for more details and findings!
                            </p>
                        </p>
                    </div>
                    <div style={{ ...right, flex: '0 1 22%', display: 'block', justifyContent: 'center', marginTop: '58px' }}>
                        <img
                            src={numeracy}
                            alt="Numeracy Project Example"
                            onClick={() => window.open("https://ishaanjavali.me/LLM%20Numeracy%20Poster.pdf")}
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                </div>

                {/* YGroove */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, flex: '0 1 77%' }}>
                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>YGroove: Personalized Dance Tutor</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>March 2024:</span> Yale Hackathon 2nd place</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%', marginBottom: '10px'
                        }}>
                            <button className="projbtns" style={{ ...Devpost }} onClick={() => window.open("https://devpost.com/software/ygroove")}>Devpost</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            Built a website that shows the user's webcam feed and an uploaded reference video side-by-side, with a graph that
                             shows the user's real-time dance similarity score, determined via 3D pose estimation and dynamic time warping algorithms.
                        </p>
                    </div>
                </div>

                {/* VidCraft */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, flex: '0 1 80%' }}>
                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>VidCraft: Educational Video Generation Platform</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>November 2023:</span> HackPrinceton AI Track Winner</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%', marginBottom: '-1px'
                        }}>
                            <button className="projbtns" style={{ ...Devpost }} onClick={() => window.open("https://devpost.com/software/project-4ub09ldsy6ao")}>Devpost</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://educational-video-generation.vercel.app/")}>Site (UI only)</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            Vidcraft is a website that allows users to enter in any topic they want to learn about and <b>instantly generate a personalized, educational video</b> with visuals and narration.
                            They can ask questions while watching via a chat interface and test their knowledge with an interactive quiz that provides detailed explanations for wrong answers.

                        </p>
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-15.5px',
                            fontSize: '16.5px', lineHeight: '1.45', color: '#444'
                        }}>
                            I designed the idea and built the end-to-end video generation pipeline <b>(GPT API + Stable Diffusion + ElevenLabs + moviepy)</b>
                        </p>
                    </div>
                </div>

                {/* Talk2Me AI Clone */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Talk2Me: My Talking AI Clone</u> ⭐</h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>July 16-20 2023</span> </b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%', marginBottom: '10px'
                        }}>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://ishaanjavali.me/talk2me")}>Try it Out!</button>
                            {/* <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p> */}
                            {/* <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/m75GaY_1BoE")}> */}
                            {/* Video Demo</button> */}
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            I built a Voice Chatbot clone of myself that speaks and sounds like me!
                            <br />
                            You can converse with it and learn more about me, my interests, experiences, goals... (conversations are private & not saved). You can even control my Clone's tone, response length, and memory.
                            {/* Simply type in messages and hear my AI Clone respond out loud. */}


                            <br />
                            <b>Try it out: </b> <span style={{ color: '#085487', fontWeight: '450', cursor: 'pointer' }} onClick={() => window.open("https://ishaanjavali.me/talk2me")}>
                                https://ishaanjavali.me/talk2me</span>
                        </p>
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-15.5px',
                            fontSize: '16.5px', lineHeight: '1.45', color: '#444'
                        }}>
                            <i>Built with React, OpenAI, ElevenLabs, and some clever prompt engineering :)</i>
                            {/* <i>Built with React, OpenAI, ElevenLabs, voice acting, and some clever prompt engineering :)</i> */}
                            {/* <i>Built with React, OpenAI, ElevenLabs, voice acting 🎤, and some clever prompt engineering :)</i> */}
                        </p>
                    </div>
                </div>

                {/* Protex tool */}
                <div id="protex" style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: '-100px', paddingTop: '100px' }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Protex: Semantic Protein Search</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>2023:</span> Scale AI's Generative AI Hackathon 2nd Place; Chroma Award</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            {/* <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/Dallas-Data-Challenge")}>GitHub</button> */}
                            {/* <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p> */}
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/prot2tex-protein-search")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/m75GaY_1BoE")}>
                                Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://prot2tex-protein-search.vercel.app/")}>
                                Site</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Screen }} onClick={() => window.open("https://www.linkedin.com/posts/ishaanjavali_generativeai-aihackathon-artificialintelligence-activity-7086371228306833408-Z1Qq?utm_source=share&utm_medium=member_desktop")}>
                                LinkedIn Post</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            We (my hackathon team & I) created protex, <b>a web tool</b> for researchers to <b>search through novel & known proteins given natural-language descriptions of relevant terms/functions</b>.

                        </p>
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-14px',
                            fontSize: '16.5px', lineHeight: '1.45', color: '#444'
                        }}>
                            <i>How it works:</i> <b>OpenAI's CLIP model</b> embeds both protein descriptions (scraped from InterPro) and the protein sequence (embedded with <b>Meta's ESM model</b>). <b>ChromaDB</b> stores embeddings and a nearest-neighbor vector search finds the most relevant proteins for user queries. Site built with: React, NextJS, Tailwind CSS & Flask.
                        </p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="85%" src="https://www.youtube.com/embed/m75GaY_1BoE" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div>
                </div>

                {/* ChatGPT Mobile App */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>ChatGPT Mobile App</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-19px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>June 2-4 2023</span> </b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/ChatGPT-Mobile-App")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://expo.dev/%40ishaanjav/ChatGPT-Mobile-App?serviceType=eas&distribution=expo-go&scheme=exp%2BChatGPT-Mobile-App&channel=main&sdkVersion=48.0.0")}>
                                Install App</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            A cross-platform mobile version of ChatGPT built with React Native
                            and Firebase for data storage.
                            <br />
                            I implemented several features such as adding chats,
                            deleting chats, navigating between chats, and modifying the chat agent's memory (how many messages it remembers).
                            The app can handle multiple users from multiple devices (lot of <b>system design</b> involved).

                        </p>
                    </div>
                    {/* <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/m75GaY_1BoE" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div> */}
                </div>


                {/* HOSPITAL BEDs */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Hospital-Bed Availability Forecasting</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-15px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>2020:</span> Dallas Data Challenge 1st place</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/Dallas-Data-Challenge")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://ishaanjavali.me/Ishaan Javali Dallas Data Challenge.ppsx")}>
                                Presentation</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.5px', lineHeight: '1.45'
                        }}>
                            I created machine learning models to  <b>predict the occupancy and availability</b> of hospital beds
                            in Dallas-Fort Worth weeks in advance based on past data.
                            <br />
                            This task of resource allocation was particularly important in 2020, as the demand for beds and ventilators was an issue. I shared my project with the City of Dallas.
                        </p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center', marginTop: '-30px' }}>
                        <p style={{ marginBottom: '0px', fontSize: '15px' }}><i>Download presentation for animations and voiceover</i></p>
                        <iframe src="https://drive.google.com/file/d/11gwODn_s2ELIEATxwifOXtBbSODcUwRL/preview" allow="autoplay"
                            style={{ width: '100%', height: '100%' }}></iframe>
                    </div>
                </div>

                {/* E-rrigation */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>E-rrigation: Mobile Sprinkler System Designer</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-17px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>'19-20:</span> DRSEF 2nd; Stockholm Junior Water Prize; Water Environment Assoc. TX</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/E-rrigation")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/ZGEaRBm8hOk")}>
                                Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://play.google.com/store/apps/details?id=app.ij.errigation")}>
                                App on Google Play</button>
                            {/* <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p> */}
                            {/* <button className="projbtns" style={{ ...Other }} onClick={() => window.open("https://ishaanjavali.me/projects")}>
                                PDF Overview</button> */}
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Screen }} onClick={() => window.open("https://ishaanjavali.me/e-rrigation.png")}>
                                Screenshots</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.15px', lineHeight: '1.45'
                        }}>
                            E-rrigation differs from all other designers in one key way: it provides estimates of <b>water wastage
                                for user-created designs</b>. <i>Wastage = overlapping and overflowing sprinklers</i>
                            <br />
                            E-rrigation offers several other features (water usage statistics, auto-plotting sprinklers, adjusting for head-to-head coverage systems)
                            <br />
                            E-rrigation is <b>computational-geometry</b> heavy. I derived <b>several mathematical equations</b> to calculate areas of overlap between 2+ circles, line segment (land area) and 1+ circles, and more.
                            Then, I implemented them and other math algorithms (Shoelace Theorem, raytracing, etc) in code, accounting for various water wastage scenarios.

                        </p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <br />
                        <br />
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/ZGEaRBm8hOk" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div>
                </div>

                {/* COVID-ID */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>COVID-ID</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>2020:</span> Crown Education Challenge Finalist</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/COVID-ID")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/VlZ5EuwT1cI")}>
                                Video Demo</button>
                            {/* <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p> */}
                            {/* <button className="projbtns" style={{ ...Other }} onClick={() => window.open("https://ishaanjavali.me/projects")}>
                                PDF Overview</button> */}
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Screen }} onClick={() => window.open("https://ishaanjavali.me/covid-id.pdf")}>
                                Screenshots</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16px', lineHeight: '1.45'
                        }}>
                            COVID-ID is an app that provides users with notifications of their COVID test results from their doctor.
                            Doctors can view a list of patients and send a status update with a note.
                            <br />
                            <b>When the pandemic struck, I designed, created, and published COVID-ID within 20 days.</b> My goal was to help
                            people return to their jobs and society with digital certification of their COVID status.
                            <br />
                            COVID-ID was taken down from Google Play because it required government/health organization authorization.
                            I reached out to local government/health organizations and healthcare professionals, gaining valuable advice.
                            As a Crown Education Challenge Finalist, it was supposed to be showcased in the <b>Harvard Tech Review.</b>
                            {/* Unfortunately, COVID-ID was taken down from Google Play because it required government/health organization authorization. As a Crown Education
                            Challenge Finalist, it was supposed to be showcased in the <b>Harvard Tech Review.</b> */}
                        </p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <br />
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/VlZ5EuwT1cI" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div>
                </div>

                {/* Donation Stations */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Donation Stations</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-18px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>July - Sep 2019</span></b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/Donation_Stations")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/hO3fDi2I73Y")}>
                                Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://play.google.com/store/apps/details?id=app.ij.donation")}>
                                App on Google Play</button>
                            {/* <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p> */}
                            {/* <button className="projbtns" style={{ ...Other }} onClick={() => window.open("https://ishaanjavali.me/projects")}>
                                PDF Overview</button> */}
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16.3px', lineHeight: '1.45', marginBottom: '8px'
                        }}>
                            Donation Stations is a mobile application that makes it
                            easy for homeless shelters to reach out to donor stores (and vice versa)
                            to request or make donations.
                            <br />
                            The app provides an informative search experience via a list and map
                            containing donors' details. Seamless donor-recipient in-app
                            communication and several more features facilitate the process.
                            <br />

                        </p>
                        <p style={{ marginTop: '0px', fontSize: '16.4px', lineHeight: '1.45' }}> I worked on the app for the Congressional App Challenge over several months, gaining more experience with Firebase, Google Maps, and Android development in general.</p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/hO3fDi2I73Y" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div>
                </div>

                {/* GitHub Popular Teachable Machine Project */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Image Classification w/ Teachable Machine</u>
                            {/* <span style={{color:'red'}}> - 12,000 installs</span> */}
                        </h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-17px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>Nov 2021 </span>
                                {/* <span style={{ color: 'red' }}>  */}
                                {/* </span> */}
                            </b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/IJ-Apps/Image-Classification-App-with-Teachable-Machine")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/jhGm4KDafKU")}>
                                Video Demo</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginBottom: '5px',
                            fontSize: '16.1px', lineHeight: '1.45', marginTop: '-5px'
                        }}>
                            Made an image classification Android app that deploys a model trained using Google's Teachable Machine.
                            <br />
                            Released a corresponding tutorial on my IJ Apps YouTube channel.
                            <br />
                            <p style={{ marginTop: '6px', fontSize: '16px', lineHeight: '1.45', fontWeight: '550', marginBottom: '4px' }}> <span style={{ color: 'red' }}>55,000+ views</span> on YouTube. <span style={{ color: 'red' }}>55+ stars</span> & <span style={{ color: 'red' }}>15+ forks</span> on GitHub.</p>
                        </p>



                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="40%" height="40%" src="https://www.youtube.com/embed/jhGm4KDafKU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            {/* <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/wjYyZwTlWEQ" allowfullscreen allow="autoplay"> */}
                            {/* </iframe> */}
                        </div>
                    </div>
                </div>


                {/* Face Analyzer */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>Face Analyzer</u>
                            {/* <span style={{color:'red'}}> - 12,000 installs</span> */}
                        </h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-17px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>Jan - Feb 2019: </span>
                                {/* <span style={{ color: 'red' }}>  */}
                                12,000+ installs
                                {/* </span> */}
                            </b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/Face_Analyzer")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/wjYyZwTlWEQ")}>
                                Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Play }} onClick={() => window.open("https://play.google.com/store/apps/details?id=app.anany.faceanalyzer")}>
                                App on Google Play</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginBottom: '5px',
                            fontSize: '16.1px', lineHeight: '1.45', marginTop: '-5px'
                        }}>
                            Face Analyzer is a mobile application that analyzes facial attributes in an image.
                            <br />
                            For each face it finds, it generates a thumbnail and estimates the person's age, emotion, gender, and head position, then displays a list.
                            <br />
                        </p>
                        <p style={{ marginTop: '0px', fontSize: '16px', lineHeight: '1.45', fontWeight: '550', marginBottom: '4px' }}> <span style={{ color: 'red' }}>12,000+ installs</span> on Google Play; 17+ stars & 11 forks on GitHub.</p>
                        <p style={{ marginTop: '0px', fontSize: '16px', lineHeight: '1.45' }}>
                            I created it initially to test emotion analysis for an information system I was making for dementia patients and their caregivers (in memory of grandfather).
                            Then, I added more features, put it on GitHub, and made it available for the public on Google Play.
                        </p>
                        {/* Many more have used my code. */}

                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <br />
                        <br />
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/wjYyZwTlWEQ?controls=0&amp;start=9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            {/* <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/wjYyZwTlWEQ" allowfullscreen allow="autoplay"> */}
                            {/* </iframe> */}
                        </div>
                    </div>
                </div>

                {/* RetnoScan */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>RetnoScan</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-17px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>2020:</span> HackRiceX: 2nd place overall; 1st place D2K Data Science</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/HackRice-X-Retinopathy-App")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/w_BNBfIiJi8")}>
                                Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Devpost }} onClick={() => window.open("https://devpost.com/software/diabetic-retinopathy-app")}>
                                Devpost</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16px', lineHeight: '1.45', marginBottom: '5px'
                        }}>
                            The RetnoScan app provides fast self-diagnosis for diabetes retinopathy.
                            We created a Convolutional Neural Network to classify healthy and retinopathic eye images and deployed it in
                            an Android app.
                            <br />
                            Users take a picture of their eye and our app will classify the image and give a recommendation.
                            Results can easily be emailed to their doctor.

                            <br />

                        </p>
                        {/* Commented out */}
                        {/* <p style={{ marginTop: '0px', fontSize: '16px', lineHeight: '1.45' }}>
                            <i>My first virtual hackathon ever and my first hackathon with this group of 3 friends. At a hackathon with college students, we never expected to win 2nd place overall or 1st in the
                                Data Science Track, but just to have fun.</i>

                        </p> */}
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <br />
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/w_BNBfIiJi8" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div>
                </div>

                {/* BirdWatch */}
                <div style={{ ...container, marginLeft: divL, marginRight: divR, marginTop: spacer }}>
                    <div style={{ ...left, }}>

                        <h3 style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-10px',
                            fontFamily: 'Poppins', fontSize: '21px', marginBottom: '2px'
                        }}>
                            <u>BirdWatch</u></h3>

                        <br />
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-17px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3', marginBottom: '3px'
                        }}><b><span style={{ color: '#666' }}>2020:</span> hackTAMS 1st place; Codestellation Best Innovation Hack</b></p>
                        <div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ ...GitHub }} onClick={() => window.open("https://github.com/ishaanjav/BirdWatch")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Presentation }} onClick={() => window.open("https://youtu.be/PYZQ59iAGSA")}>
                                Video Demo</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ ...Devpost }} onClick={() => window.open("https://devpost.com/software/birdwatch-hb8kvi")}>
                                Devpost</button>
                        </div>

                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: descriptionSpacingTop,
                            fontSize: '16px', lineHeight: '1.45', marginBottom: '7px'
                        }}>
                            <b>Ever seen a bird and wondered what species it was?</b>
                            <br />
                            BirdWatch is an eco-tourism app that classifies bird species and identifies their endangerment level, all from a picture.
                            Users' findings are automatically crowdsourced to improve the app and notify local authorities of endangered species in an area.

                        </p>
                        <p style={{ marginTop: '0px', fontSize: '16px', lineHeight: '1.45' }}>
                            Applying transfer learning, we developed a CNN model based off MobileNetV2's architecture to classify birds. We deployed the model
                            in an Android app. We developed a React web app for authorities displaying locations where endangered birds were found.

                        </p>
                    </div>
                    <div style={{ ...right, display: 'block', justifyContent: 'center' }}>
                        <br />
                        <div class="video_wrapper">
                            <iframe class="video_frame" frameborder="0" width="100%" height="100%" src="https://www.youtube.com/embed/PYZQ59iAGSA" allowfullscreen allow="autoplay">
                            </iframe></div>
                    </div>
                </div>


            </div >
        )
    }
}
const container = {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
}
const left = {
    flex: '0 1 65%', /*  No stretching: */
    // flex: '0 1 750px', /*  No stretching: */
    margin: '5px'
}
const right = {
    flex: '0 1 32%', /*  No stretching: */
    // flex: '0 1 350px', /*  No stretching: */
    margin: '5px'
}
const divStyle = {
    width: '100vw',
    // textAlign: "center",
    paddingTop: spacing.sectionHeader,
    // paddingBottom: "44px",
    paddingBottom: '40px',
    // background: colors.color3,
    // background: '#e0f3ff',
    background: '#fff',
    // background: '#e6f5ff',
    // background: 'white',
    minHeight: '10em',
    // display: 'table-cell',
    verticalAlign: 'middle',
    // display: 'flex',
    // flexWrap: 'wrap',
    // marginTop: '-50px'

}
export default Projects

// export default IJ

// 

/*
<div style={{
                            overflow: 'hidden', width: '100%'
                        }}>
                            <button className="projbtns" style={{ background: '#222', color: 'white' }} onClick={() => window.open("https://github.com/ishaanjav/Dallas-Data-Challenge")}>GitHub</button>
                            <p style={{ float: 'left', marginTop: '2px', color: '#4d75a3', fontWeight: '700' }}> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <button className="projbtns" style={{ background: '#d3522f', color: 'white' }} onClick={() => window.open("https://ishaanjavali.me/Ishaan Javali Dallas Data Challenge.ppsx")}>
                                Presentation</button>
                        </div>
                        <br />
                        <p style={{
                            float: 'left', marginLeft: mLeft, marginTop: '-25px',
                            fontSize: '17.5px', fontFamily: 'Lato', color: '#4d75a3'
                        }}><b><span style={{ color: '#666' }}>2020:</span> <i >Dallas Data Challenge 1st place</i></b></p>

*/