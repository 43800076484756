import React, { Component } from 'react'

export class RedirectorGen extends Component {
    componentDidMount() {
        console.log("Redirecting to:", this.props.link);
        window.location.href = this.props.link;
    }

    render() {
        return (
            <div>
                Redirecting...
            </div>
        )
    }
}

export default RedirectorGen
