import Particles from 'react-particles-js';
import { particlesConfig } from '../constants/particlesConfig'
import React, { Component } from 'react'
import { colors, links, type } from '../constants/Constants.js'
import { isMobile, isMobileOnly } from 'react-device-detect';
import Typist from 'react-typist';
import Typical from 'react-typical'
import FadeIn from 'react-fade-in';
import { FaLinkedin, FaGithub, FaYoutube } from 'react-icons/fa';
import { HiDocument, HiDocumentText } from 'react-icons/hi';
import { BsFileEarmarkText } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { RiFileTextLine } from 'react-icons/ri';

// https://github.com/VincentGarreau/particles.js/
// TODO: Figure out how to position my name, 40% from the top, not 50%.
const oSize = '38', oColor = '#3d3d3d'
class ParticlesNew extends Component {
    state = {
        // size of the images. This state variable will need to change on resize.
        size3: oSize,
        color3: oColor,
        size1: oSize,
        color1: oColor,
        size2: oSize,
        color2: oColor,
        size4: oSize,
        color4: oColor,
        // color: '#ffeec4',
        bg: '#67c4e0',
        // bg: '#ff5454',
        fp: 'linear-gradient(65deg, #3b96ff, ',
        header: '152px',
        particlesConfig: particlesConfig,
        boxWidth: "410px",
    }
    get(a) {
        if (a == "linkedin")
            return "M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z";
        else if (a == "git")
            return "M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z";
        else if (a == "resume")
            return "M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z M4 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4zM3 2v12h8V2H3zm2 3h4a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm0 3h4a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm0 3h4a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2z";
        else if (a == "mail")
            return "M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z";
        //youtube
        else
            return "M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z";
    }
    //TODO do animations for box and my name
    animate() {

    }
    componentDidMount() {
        return;
        // console.log("JRTRasdf")
        this.animate();
        window.addEventListener('resize', this.updateDimensions);
        // just use red top with blue background
        var whoCares = false;
        if (whoCares) {
            // colors.mode = 'red'
            this.setState({ fp: 'linear-gradient(90deg, #ff673d, ' });
            this.setState({ bg: '#ffa600' })
            this.state.particlesConfig.particles.color = '#bfe3ff'
            // this.setState({ particlesConfig: '#ff8317' })
            return;
        }
        if (colors.mode == 'red')
            this.setState({ bg: '#f66, #f66' })
        // console.log("inn " + window.innerWidth)
        if (isMobile) {
            this.setState({ header: '22vmin' })
            if (window.innerWidth < 460) {
                // console.log("HERE " + window.innerWidth - 40)
                this.setState({ boxWidth: (window.innerWidth - 60) + 'px' })
            }
        }

        var idx = 0;
        //          light blue    green     strong green  purple      red      orig blue
        var cycle = ['#85d0ff', '#33ffee', '#43d986', '#8754ff', '#ff5454', '#3b96ff']
        if (colors.mode == 'red') {
            this.setState({ fp: 'linear-gradient(155deg, #ff5454, ' })
            this.setState({ bg: '#ff5454' })
            //        light red    orange     strong green  purple      red      orig blue
            cycle = ['#ffbaba', '#ffca61', '#ffd633', '#ff9861', '#ff5454']
        }
        var before = this.state.bg;
        var test = this;
        // README: fb4a19 f8b231 is better for red gradient. First is left second is right color
        if (!colors.change) {
            if (colors.mode == "red") {
                this.setState({ bg: '#ff8317' })
            } else {
                // even lighter
                // this.setState({ bg: '#85d0ff' })
                this.setState({ bg: '#69c5ff' })
            }
            return;
        }
        var freq = 50;
        var tot = 270;
        var time = freq * tot + 2000
        var outerInterval = setInterval(function () {
            var target = cycle[idx];
            var times = 0;
            // var freq = 60;
            let origA = parseInt(before.substring(1, 3), 16)
            let origB = parseInt(before.substring(3, 5), 16)
            let origC = parseInt(before.substring(5, 7), 16)
            let tA = parseInt(target.substring(1, 3), 16)
            let tB = parseInt(target.substring(3, 5), 16)
            let tC = parseInt(target.substring(5, 7), 16)
            var interval = setInterval(function () {
                times++;

                var fraction = times / (tot)
                // console.log("FRAC: ", fraction + " " + origA + "," + tA)
                var a = Math.floor(origA + fraction * (tA - origA));
                var b = Math.floor(origB + fraction * (tB - origB));
                var c = Math.floor(origC + fraction * (tC - origC));
                // console.log(a + " " + origA)
                // if (a <= tA || b <= tB || c <= tC) {
                // a = tA; b = tB; c = tC;
                // }
                // console.log(a + "  " + origA + " --> " + tA)
                var hexString = " #" + a.toString(16) + b.toString(16) + c.toString(16);
                test.setState({ bg: hexString });
                // console.log("TEST");
                if (a == tA) {
                    clearInterval(interval);
                    return;
                }
            }, freq);
            before = cycle[idx]
            idx = (idx + 1) % cycle.length
        }, time);
    }
    constructor(props) {
        super(props)
        // this.enter = this.enter.bind(this);
        // this.leave = this.leave.bind(this);
        // console.log("prop")

        // README: Change number of particles/particles settings based on device
        if (isMobileOnly) {
            var particlesSettings = this.state.particlesConfig;
            particlesSettings.particles.number.max = 30
            particlesSettings.particles.number.value = 14
        } else if (isMobile) {
            var particlesSettings = this.state.particlesConfig;
            particlesSettings.particles.number.max = 37
            particlesSettings.particles.number.value = 20
        }
        this.header = React.createRef();
        // this.componentDidMount()
    }
    enter(id) {
        var v1 = "color" + id
        var v2 = "size" + id
        if (colors.uniform) {
            this.setState({ [v1]: '#000', [v2]: '36' })
        } else {
            var newCol = '#f00'
            if (id == '1') // linkedin
                newCol = '#0074d9'
            else if (id == '2' || id == '3') // github or resume
                newCol = '#000'
            this.setState({ [v1]: newCol, [v2]: '36' })
        }
    }
    leave(id) {
        var v1 = "color" + id
        var v2 = "size" + id
        this.setState({ [v1]: oColor, [v2]: oSize })
    }
    updateDimensions = () => {
        var w = window.innerWidth;
        var h = window.innerHeight;
        // README logging the width
        // console.log("width: " + w); console.log(this.header.current.offsetWidth)
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    render() {
        var a = <p> </p>
        if (type.type) {
            a = <div style={{ marginTop: '-50px', userSelect: 'none' }}>
                <span style={{ color: "#fff" }}>A </span>
                <Typical
                    steps={['Builder', 3000, 'Researcher', 3000,
                        'Open-Source Developer', 3000, 'Deep Learner', 3000, 'Mentor', 3000,]}
                    loop={Infinity}
                    wrapper="span"
                // style={{ color: 'red' }}
                />
            </div>
        }
        return (
            <div className="App" style={{
                // display: 'inline-block',
                // position: 'relative',
                // background: '#67a5fc', height: '100vh', width: '100%'
                // background: '#5ea6ff',
                // background: '#459cff',
                // background: '#3b96ff',
                // backgroundImage: 'linear-gradient(30deg,#3b96ff, #3dffb8)',
                // backgroundImage: 'linear-gradient(135deg,#3b96ff, #85d0ff)',
                backgroundImage: this.state.fp + this.state.bg,
                height: '100vh', width: '100%',
                display: 'table', textAlign: 'center',
            }}>
                <Particles
                    style={{ height: '90vh', marginLeft: '0%', position: "absolute", marginTop: "0px", zIndex: 99 }}
                    params={this.state.particlesConfig}
                />
                <br></br>

                {/* Make the icons turn orange when you hover on them. */}
                <div style={{
                    zIndex: 100, position: "relative",
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    marginBottom: '300px'
                }}>
                    {/* <Typist>
                        <span>A Programmer</span>
                        <Typist.Backspace count={10} delay={2000} />
                        <span> YouTuber</span>
                        <Typist.Backspace count={8} delay={2000} />
                        <span> Phrase </span>
                    </Typist> */}
                    <h1 ref={this.header} style={{
                        fontFamily: 'Raleway',
                        fontFamily: 'Nunito',
                        color: '#fff',
                        fontSize: this.state.header,
                        // fontSize: '10vw',
                        userSelect: 'none',
                        marginLeft: '10px', marginRight: '10px'
                    }}>
                        Ishaan  Javali
                    </h1>
                    {a}
                    <br></br>
                    <div style={{
                        borderRadius: '20px', background: 'rgba(255,255,255,0.45)', alignSelf: 'center',
                        width: this.state.boxWidth, display: 'inline-block',
                        // marginRight: '10px', marginLeft: '10px',
                    }}>
                        {/* TODO Use the fade in thing for animation. Right now it increases the size of the table */}
                        <table style={{}}>
                            <tr>
                                {/* <FadeIn style={{ background: '#f00', padding: '0px', marign: '0px' }}> */}
                                <th style={{ cursor: 'pointer', }} onMouseEnter={() => { this.enter("1") }} onMouseLeave={() => { this.leave("1") }} onClick={() => window.open(links.linkedin)}><MenuIcon icon="linkedin" size={this.state.size1} fill={this.state.color1} /></th>
                                {/* </FadeIn> */}
                                <th style={{ cursor: 'pointer', }} onMouseEnter={() => { this.enter("2") }} onMouseLeave={() => { this.leave("2") }} onClick={() => window.open(links.github)}><MenuIcon icon="git" size={this.state.size2} fill={this.state.color2} /></th>
                                <th style={{ cursor: 'pointer', }} onMouseEnter={() => { this.enter("3") }} onMouseLeave={() => { this.leave("3") }} onClick={() => window.open("https://ishaanjavali.me/resume")}><MenuIcon icon="resume" size={this.state.size3} fill={this.state.color3} /></th>
                                <th style={{ cursor: 'pointer', }} onMouseEnter={() => { this.enter("4") }} onMouseLeave={() => { this.leave("4") }} onClick={() => window.open(links.youtube)}><MenuIcon icon="youtube" size={this.state.size4} fill={this.state.color4} /></th>
                            </tr>
                        </table>
                    </div>

                </div>

            </div>
        );
    }
}
const MenuIcon = (props) => {
    const iconStyle = { width: props.size, height: props.size, fill: props.fill };

    switch (props.icon) {
        case "linkedin":
            return <FaLinkedin style={iconStyle} />;
        case "git":
            return <FaGithub style={iconStyle} />;
        case "resume":
            return (
                <div style={{ position: 'relative' }}>
                    <RiFileTextLine style={iconStyle} />
                    <span style={{
                        position: 'absolute',
                        bottom: '-14px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '11px',
                        color: props.fill,
                        fontFamily: 'Nunito',
                        whiteSpace: 'nowrap'
                    }}>
                        Resume
                    </span>
                </div>
            );
        case "youtube":
            return <FaYoutube style={iconStyle} />;
        default:
            return null;
    }
}
const imgStyle = {
    width: '30%',
}
export default ParticlesNew;
