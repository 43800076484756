import React from 'react';

const ScalingCRL = () => {
  return (
    <iframe 
      src="/subpages/scaling-crl/index.html"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none'
      }}
      title="Scaling CRL"
    />
  );
};

export default ScalingCRL;