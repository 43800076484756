import React, { Component } from 'react'
import data from '../images/awards/data.png'
import googs from '../images/awards/googs.png'
import { colors, spacing } from '../constants/Constants.js'
import AwardRow from '../text-items/AwardRow'
import "../styles/Awards.css"
import { styled } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

/* 
Dallas Data Challenge
That Harvard one
USACO Gold
AIME Qualifier
TXSEF Qualifier
Kiwanis Scholarship
*/

/* 
Looking at about another 27. So that's nice. Perfect 4 slides.

d - HackRice X - 1st place Data Science, 2nd overall
d - HooHacks 2021 - Data Science Winner
d - hackTAMS - 1st place (BirdWatch)
d - UTD Battle of the Brains - First place 2021 Fall, 2020 Fall, 2020 Spring, 2nd place
2019 Fall or just write 1st place 2x, 2nd place 1x
d - Codestellation Hackathon - Best Innovation Hack (BirdWatch)

d - Community AI Fair - Finalist
d - Clean Tech Competition - Semi Finalist
d-Sigma Xi Research Showcase - People's Choice Award

d- Purple Comet Math Meet - 1st team in Texas 

URTC if applicable

d - Google KickStart Round A - Temporarily 13th in the world!
Google Code Jam - Round 1 Qualifier 2x   <- Skip?
d - Texas Junior Science & Humanities Symposium (TJSHS) Presenter
d - Texas Junior Academy of Sciences (TJSHS) 3rd place 2x times
d - First Tech Challenge (Robotics) - Regional qualifier.


d - TAMU Power Team Contest - 7th

d - Best of Texas Written CS Contest - 1st place in state

d - Have 3 separate ones for UIL Comp Sci at each level
and put 1st individually and 1st team.
d - UIL Math 1st palce team in math for district.

Keller Programming Competition - 3rd in coding, 5th in written
d - 2020 11 - Clements HS Programming Competition - 1st in DFW
d - 2020 10 - Code LM HS Programming Competition - 1st place
d - Country Day HS Programming Competition - 1st place
d - Frisco First Bytes Programming Competition - 1st place
d - 2020 10 - Beta Club State Competition - 1st place
*/
/* 
Dropdown:
Science Fairs and Research Competitions
Programming Competitions
Hackathons
Math Competitions
Maybe make research a category based on # of things

TODO:
Figure out how to mention Best in State of Texas, naybe in its own tile, without being too repetitive.
  I don't want to have 2 TXSEF tiles. Maybe next line? Maybe small text on 3rd line, italicized "2nd Best in State for Physical Sciences (2021)"
NEED TO ADD DATES
Looking at this and they have no idea what you did now and what you did in the past.
Except for first 9 tiles, organize rest by date.
Show the date somewhere.
Or you know where you were thinking of having the dropdown, on the opposite side
put "10th grade". "11th grade" when they click.

CREATIVITY REALLY COOL HUMBLENESS:
Have something where if they reach the end of the list, it then disapperas
and shows text saying "Ah so you've made it to the end of the list. Now see my failures. or something"
But like "I don't always win. In fact, I have more failures than I do achievements, but I've learned from those achievements"
Then show like UTD Battle of the Brains 2018 Fall last place.
And things like that that can show improvment over time

Or if you don't do the failures section, at least add the 2019 Fall Last place next to the tile for first place
So title:"2018 battle of the Brains Last Place" text:"Click for more info" --> then describe your story.

Another one for Frisco First Bytes: Fall 2018. Close to last place.
*/

/* 
NEED:
Dropdown
Alert Dialog
Pagination

*/
/* 
TODO:
1. Figure out how to add margin to outer columns of f awards
*/

// Or Create your Own theme:
const theme = createMuiTheme({
    palette: {
        secondary: {
            // main: '#c2e4ff'
            // main: '#cfeaff'
            main: '#fff'
        }, root: {
            color: "#FFFFFF"
        }, allVariants: {
            color: "#fff"
        },
    }, typography: {
        allVariants: {
            color: "#fff"
        },
    },
});
var prev = 1

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const TTypography = withStyles({
    root: {
        color: "#000"
    }
})(Typography);

const Title = withStyles({
    root: {
        color: "#000"
    }
})(DialogTitle);

class Awards extends Component {

    handleChange = (e, newVal) => {
        // console.log(prev, newVal)
        if (prev == newVal) return;
        var idx = newVal - 1
        this.setState({ id: idx })
        prev = newVal;
    }
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.tileClicked = this.tileClicked.bind(this);
        this.setFalse = this.setFalse.bind(this);
        this.state = {
            open: false,
            show: {
                'ISEF': false, 'USACO': false, 'AIME': false, 'YaleHack': false, 'HackPrinceton': false,
                'Lockheed': false, 'Crown': false, 'SJWP': false, 'USAID': false, 'KickStart': false, 'SCALE': false, 'COSCON': false,
            },
            ISEF: false,
            USACO: false,
            AIME: false,
            YaleHack: false,
            HackPrinceton: false,
            Lockheed: false,
            Crown: false,
            SJWP: false,
            USAID: false,
            KickStart: false,
            SCALE: false,
            COSCON: false,
            layout: [
                <div style={{ width: '87%', display: 'inline-block', zIndex: '100' }}>
                    <table>
                        <tr>
                            <td ><AwardRow name="ISEF" t='S' row={0} place="ISEF" text="1st place" clicker={this.tileClicked} /></td>
                            <td><AwardRow name="USACO" t='C' row={0} place="USACO Gold" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="AIME" t='M' row={0} place="AIME" text="3x Qualifier" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            <td> <AwardRow name="SCALE" t='S' row={2} text="2nd place" place="Scale AI Generative AI Hackathon" clicker={this.tileClicked} /></td>
                            <td><AwardRow name="USAID" t='S' row={2} place="USAID Special Award" text="" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="KickStart" top="2px" t='C' size1="17px" size2="17px" row={1} text="Temporarily 13th! Finished 157th / 19800+ (top 0.8%)" place="Google KickStart Round A" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            <td ><AwardRow name="HackPrinceton" t='H' row={1} text="AI Track Winners" place="HackPrinceton" clicker={this.tileClicked} /></td>
                            <td ><AwardRow name="YaleHack" t='H' row={1} text="2nd place" place="Yale Hackathon" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="COSCON" t='S' row={2} text="2nd place" place="Princeton CS Contest" clicker={this.tileClicked} /></td>
                        </tr>
                    </table>
                </div>
            ]
        }
    }
    tileClicked = (name) => {
        console.log("Clicked " + name)
        let showCopy = { ...this.state.show };
        showCopy[name] = true
        // showCopy['ISEF'] = true
        this.setState({ [name]: true })
    }
    handleClickOpen = () => {
        this.setState({ open: true })
    };
    handleClose = () => {
        this.setState({ open: false })
    };
    setFalse = (name) => {
        this.setState({ [name]: false })
    };
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <div style={divStyle}>
                    <p style={{
                        fontSize: '34px',
                        fontWeight: '750',
                        marginTop: '-55px',
                        color: 'white', userSelect: 'none'
                    }}>Featured  Awards</p>
                    <p style={{ fontSize: '19px', marginTop: '-2px', marginBottom: '12px', color: '#fff4bf', fontWeight: '630', userSelect: 'none' }}>
                        Click on a tile to view more info
                        <br></br>
                    </p>
                    {this.state.layout[0]}
                    <br></br>

                    <Dialog onClose={() => this.setFalse("ISEF")} aria-labelledby="customized-dialog-title" open={this.state.ISEF}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>ISEF   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Placed 1st in category at ISEF 2021 with my research project with my partner.
                                    <br></br>
                                    We created a Global Landslide Analytics System  accomplishing 3 tasks: </p>
                                <ol style={{ marginTop: '-15px' }}>
                                    <li>Global dataset of landslide incidences <b>and</b> climate and terrain features at time of landslide</li>
                                    <li>Landslide forecasting models with 86.9% TPR for 5-day forecasts</li>
                                    <li>Data-driven susceptibility mapping based off terrain features.</li>
                                </ol>
                                <p><u><b>Other Distinctions:</b></u></p>
                                <ul style={{ marginTop: '-15px' }}>
                                    <li><b>USAID Science for Development Award first place</b></li>
                                    <li><b>Presented at IEEE MIT URTC</b></li>
                                    <li><b>Paper published in IEEE Xplore</b></li>
                                    <li><b>Texas State Science & Engineering Fair:</b> 1st place category & 2nd place Grand Prize</li>
                                    <li>American Statistical Association Award</li>
                                    <li>Mu Alpha Theta Award</li>
                                </ul>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '20px' }} className="dialoglinks" onClick={() => window.open("/poster")}>
                                    Abstract and Poster</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("/landslide-research")}>
                                    Research Paper</button>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("USACO")} aria-labelledby="customized-dialog-title" open={this.state.USACO}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>USACO   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Qualified for the USACO Gold Division after
                                    several months of self-learning algorithms and data structures and practicing solving problems.</p>
                                <br></br>
                                <b>I love competitive programming because it applies algorithms and data structures to challenging, thoughtprovoking problems in a timed, competitive environment while promoting
                                    both efficient coding and efficient code.</b>
                                <br></br>
                                <p>I've open-sourced my solutions to 100s of problems on GitHub:</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/USACO-Solutions")}>
                                    100+ USACO Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Codeforces-Solutions")}>
                                    200+ Codeforces Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Google-Kick-Start-And-Code-Jam-Solutions")}>
                                    Google Kick Start & Code Jam</button>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("AIME")} aria-labelledby="customized-dialog-title" open={this.state.AIME}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>AIME</h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2019 & 2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Qualified for the AIME in 9th and 11th grades.</p>
                                <p style={{ marginTop: '0px', fontSize: '17px' }}>Score of 8 (2021) is in top 10%.</p>
                                <p style={{ marginTop: '0px', fontSize: '17px' }}>I enjoy learning advanced concepts in my free time and competing
                                    in math competitions, both individually and with a team. I also enjoy proof-based contests like USAMTS and TAMU Power Team.</p>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("YaleHack")} aria-labelledby="customized-dialog-title" open={this.state.YaleHack}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>Yale Hackathon</h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2024</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Won 2nd place at Yale's hackathon with my team for creating <b>YGroove</b>, a personalized dance tutoring platform.</p>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '16px', marginTop: '10px' }}>
                                    YGroove enables real-time similarity scoring between a user's dance movements and an uploaded reference video.
                                    The platform uses  3D pose estimation
                                    and dynamic time warping algorithms to provide instant feedback on dance performance  in the form of a graph that plots the user's performance while they dance in front of the camera.</p>

                                {/* <p>This feedback is presented</p> */}
                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', marginTop: '10px' }}>
                                    Key features include:
                                </p>
                                <ul style={{ marginTop: '-15px' }}>
                                    <li>Real-time pose estimation and tracking</li>
                                    <li>Dynamic time warping for movement comparison</li>
                                    <li>Intuitive web interface for video upload and playback</li>
                                    <li>Detailed feedback on movement accuracy</li>
                                </ul> */}
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '20px' }} className="dialoglinks" onClick={() => window.open("https://devpost.com/software/ygroove")}>
                                    View Project on Devpost</button>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("USAID")} aria-labelledby="customized-dialog-title" open={this.state.USAID}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>USAID   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Won U.S. Agency for International Development Science for Development Award at ISEF ($10,000 award).</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '-7px' }} className="dialoglinks" onClick={() => window.open("https://www.usaid.gov/research/ISEF")}>
                                    USAID Article</button>
                                <p><i>See the ISEF card for more details on the project.</i></p>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("HackPrinceton")} aria-labelledby="customized-dialog-title" open={this.state.HackPrinceton}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>HackPrinceton   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2023</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Won the AI Track at HackPrinceton with my team for creating <b>Vidcraft</b>, an innovative educational platform.</p>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', marginTop: '10px' }}>
                                    Vidcraft is a website that allows users to enter in <i>any topic</i> they want to learn about and instantly generate a personalized educational video with visuals and narration.

                                    Users can interact with the video through a chat interface to ask questions, and upon completion, test their knowledge with an interactive quiz that provides detailed explanations for wrong answers.</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '20px' }} className="dialoglinks" onClick={() => window.open("https://devpost.com/software/project-4ub09ldsy6ao")}>
                                    View Project on Devpost</button>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("SCALE")} aria-labelledby="customized-dialog-title" open={this.state.SCALE}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ fontSize: '28px', marginLeft: '16px', display: 'inline-block', float: 'left' }}>Scale AI's Generative AI Hackathon  </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>July 2023</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    Scale AI hosted their 2nd ever Gen AI Hackathon in San Francisco on July 15th. (it was a 1-day hackathon)</p>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px', marginTop: '0px' }}>
                                    We (my hackathon team & I) created <b>protex</b>, a web tool for researchers to search through novel & known proteins given natural-language descriptions of relevant terms/functions.</p>
                                <p style={{ marginTop: '-5px', marginBottom: '35px' }}><i>How it works:</i> OpenAI's CLIP model embeds both protein descriptions (scraped from InterPro) and the protein sequence (embedded with Meta's ESM model). ChromaDB stores embeddings and a nearest-neighbor vector search finds the most relevant proteins for user queries. Site built with: React, NextJS, Tailwind CSS & Flask.</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '-7px' }} className="dialoglinks" onClick={() => window.open("https://youtu.be/m75GaY_1BoE")}>
                                    Video Demo </button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://www.linkedin.com/posts/ishaanjavali_generativeai-aihackathon-artificialintelligence-activity-7086371228306833408-Z1Qq?utm_source=share&utm_medium=member_desktop")}>
                                    LinkedIn Post </button>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("COSCON")} aria-labelledby="customized-dialog-title" open={this.state.COSCON}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ fontSize: '28px', marginLeft: '16px', display: 'inline-block', float: 'left' }}>Princeton COSCON</h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2023</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    Won 2nd place at Princeton's COSCON, a 1-day Computer Science contest covering problems in algorithms & data structures,
                                    math proofs, machine learning, cybersecurity, and even requiring some Assembly! </p>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    I handled the algorithms & data structures problems as well as machine learning. </p>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("KickStart")} aria-labelledby="customized-dialog-title" open={this.state.KickStart}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>Google Kick Start   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>'Twas one silent night in March of 2021. I was reclining sleepily in my chair
                                    rewatching X-Men: First Class, my favorite X-Men film. The first round of Google Kick Start was starting at 11:00 PM so I decided what better way to kill some time than with "my fellow mutants"?
                                    <br></br>
                                    I'd just gotten to the part where Charles and Erik were playing the first of their iconic chess matches when I glanced at the time. It was 10:55. I paused the movie and sat up straight.
                                    Visual Studio Code became my canvas; my computer, my palette; my keystrokes, my brushstrokes. When the contest started I was ready to paint.
                                    <br></br>
                                    <br></br>
                                    Much to my amazement, 3 problems in I was ranked 13th on the international leaderboard alongside former IOI gold medallists.
                                    <br></br>
                                    I finished 157th out of over 19,800 contestants, the top 0.8%.
                                    <br></br>
                                    That was an unforgettable experience that left me excited, humbled, and motivated! Once the contest ended I returned to watching the movie, "mutant and proud".
                                    <br></br>
                                    <br></br>
                                    <b>I love competitive programming because it applies algorithms and data structures to challenging, thoughtprovoking problems in a timed, competitive environment while promoting
                                        both efficient coding and efficient code.</b>
                                </p>
                                <p>I've open-sourced my solutions to 100s of problems on GitHub:</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/USACO-Solutions")}>
                                    100+ USACO Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Codeforces-Solutions")}>
                                    200+ Codeforces Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Google-Kick-Start-And-Code-Jam-Solutions")}>
                                    Google Kick Start & Code Jam</button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </MuiThemeProvider>)
    }
}

const divStyle = {
    width: '100%',
    textAlign: "center",
    paddingTop: spacing.sectionHeader,
    paddingBottom: '51px',
    background: '#47b0fe',
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle'
}

const lColumn = {
    width: '33.3%',
    float: 'left',
    marginLeft: '200px'
}

const cColumn = {
    width: '33.3%',
    display: 'inline-block'
}

const rColumn = {
    width: '33.3%',
    float: 'right'
}

const container = {
    marginTop: '49px',
    columnCount: '3',
    columnRuleWidth: '5%'
}

export default Awards
