import React, { Component } from 'react'
import NavigationBar from '../components/NavigationBar'
import EmptyBar from '../other/EmptyBar'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import About from '../components/About'
import Awards from '../components/Awards'
import Resume from '../components/Resume'
import Projects from '../components/Projects'
import Test from '../components/Test'
import { Document, Page } from 'react-pdf'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Resume from './Resume.pdf'
// import { CSSTransitionGroup } from 'react-transition-group'
// import PDFViewer from 'pdf-viewer-reactjs'
import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';
import MobileNavBar from '../components/MobileNavBar'
import Experience from '../components/Experience'
import Orig from '../components/Orig'
import Wave from '../backgrounds/Wave'
import Wave2 from '../backgrounds/Wave2'
import WaveIJ from '../backgrounds/WaveIJ'
import Wave3 from '../backgrounds/Wave3'
import ThinBar from '../other/ThinBar'

const array = ["a", "b", "c"];
function test(props) {
    return <p>Tasdfasdfasdfest</p>
}
/* 
TODO:
 1. Get PDF to show
*/
class Home extends Component {
    render() {
        return (

            <div style={{ background: '#fff', alignItems: 'center', ...divStyle }}>
                <Test />

                <MobileOnlyView>
                    <MobileNavBar home={true} />
                </MobileOnlyView>

                <CustomView condition={isMobileOnly === false}>
                    <NavigationBar home={true} />
                </CustomView>

                {/* <EmptyBar height='72px' /> */}
                {/*   <ReactCSSTransitionGroup
          transitionName="background"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
          >
        </ReactCSSTransitionGroup> */}

                {/*   <Document
          file={Resume}
          >
          <Page pageNumber={1} />
        </Document> */}

                {/* <div style={{ background: '#edf3f7', width: '100%', display: 'block' }}> */}
                <About />
                <EmptyBar height='14px' id="experience" />
                <Experience />

                <EmptyBar height='2px' id="projects" />
                <Projects style={{ width: '100vw' }} />

                <Wave2 height='25vh' />
                <Awards style={{ width: '100vw' }} />
                <div style={{ marginTop: '-20px', width: '100%', height: '42px', background: 'linear-gradient(150deg, #47b0fe, #47b0fe, #fff', transform: 'rotate(1deg)' }}></div>

                <EmptyBar height='50px' id="ijapps" />
                <Orig style={{ width: '100%' }} />

                <div style={{ width: '100%', background: 'white' }}>
                    <Contact />
                    <Footer />
                </div>
            </div >

        )
    }
}
// Magic lines below. The top 3
const divStyle = {
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle',
    // display: 'flex', alignItems: 'center',
    // justifyContent: 'center'
}
export default Home