import React, { Component } from 'react'
import { colors, spacing } from '../constants/Constants.js'
import Collapsible from 'react-collapsible';
import Ishaan from '../images/meIJ.jpg'
import aysi from '../images/aysi.jpeg'
import justicecoin from '../images/justicecoin.jpeg'
import utd from '../images/utd.jpeg'
import skunk from '../images/skunk.png'
import lockheed from '../images/lockheed.png'
import pni from '../images/pni.png'
import ev3 from '../images/ev3.png'
import east from '../images/east.jpeg'
import ringle from '../images/ringle.png'
import gm from '../images/gm.png'
import golf from '../images/golf.jpeg'
import goldengatebridge from '../images/goldengatebridge.jpg'
import citsec from '../images/citsec.jpg'

import blob from '../backgrounds/blob.svg'
import dots from '../backgrounds/dots.svg'

import "../styles/Exp.css";
import EmptyBar from '../other/EmptyBar.js';

const tarS = '844', tarV = '175', tarH = '56';
var times = 0;
var curS = 0, curV = 0, curH = 0;
var duration = 2500; // how many seconds to animate for
var tots = 80; // how many updates to do 
var delay = 630; // how many seconds to wait

// Lockheeds
// top3Health
// JusticeCoin
// EV3 Everywhere
// AYSI Lecture Program VP
const space = '9px'
const space2 = space
var imageW = "53px";
var leftMarg = '12px';
var leftP = '32%';
var fSize = '19.2px';
var mgB = '10px'; // bottom of each card
var smaller = '18.5px';
// const divB = '#d6edff'

// comment out below divB and content of card to go back to original
// var back = true
var back = false
var animateOneTimeOnly = true
var divB = '#fff'
if (back)
    divB = '#efefef'
// const divB = '#efefef'
var card = {
    background: '#ffffff',
    padding: '8px 16px',
    borderRadius: '16px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    transformStyle: 'preserve-3d',
    position: 'relative',
    overflow: 'hidden'
}
const pB = '85px';
export class Experience extends Component {
    state = {
        top: '500',
        scrollY: 0,
        enableGlow: false,
        visibleCards: new Set(),
        lastScrollY: 0
    }

    toggleGlow = () => {
        this.setState(prevState => ({
            enableGlow: !prevState.enableGlow
        }));
    }

    componentDidMount() {
        // Set up Intersection Observer for the main title
        const titleObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const mainTitle = entry.target;
                if (entry.isIntersecting) {
                    mainTitle.style.transitionDelay = '0s';
                    mainTitle.classList.add('visible');
                    mainTitle.classList.remove('hiding');
                } else {
                    mainTitle.style.transitionDelay = '0s';
                    mainTitle.classList.remove('visible');
                    mainTitle.classList.add('hiding');
                }
            });
        }, {
            root: null,
            rootMargin: '10% 0px', // Positive margin to trigger earlier
            threshold: 0.5 // Trigger when just 1% of the element is visible
        });

        // Start observing the main title
        const mainTitle = document.querySelector('.main-title');
        if (mainTitle) {
            titleObserver.observe(mainTitle);
        }

        // Existing scroll handler setup
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();

        setTimeout(() => {
            const cards = document.querySelectorAll('.experience-card');
            console.log('Found cards:', cards.length);

            cards.forEach(card => {
                console.log('Adding listeners to card');
                card.addEventListener('mousemove', (e) => {
                    console.log('Mouse moved over card');
                    card.style.transform = 'translateY(-3px)';
                    card.style.boxShadow = '0 10px 30px rgba(66, 153, 225, 0.25)';
                });

                card.addEventListener('mouseleave', () => {
                    console.log('Mouse left card');
                    card.style.transform = 'translateY(0)';
                    card.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.05)';
                });
            });
        }, 100);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        const cards = document.querySelectorAll('[style*="card"]');
        cards.forEach(card => {
            card.replaceWith(card.cloneNode(true));
        });
    }

    handleScroll = () => {
        const cards = document.querySelectorAll('.experience-card');
        const sectionTitles = document.querySelectorAll('.section-title:not(.main-title)');
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const triggerLineDown = windowHeight * 0.95; // 98% from top of viewport
        const triggerLineUp = windowHeight * 0.965; // 100% from top (bottom of viewport)
        const aeronauticsTriggerLine = windowHeight * 0.98; // 99% from top of viewport

        // Handle section titles
        sectionTitles.forEach(title => {
            const titleTop = title.getBoundingClientRect().top;
            const titleBottom = title.getBoundingClientRect().bottom;
            const isVisible = titleTop < windowHeight * 0.9 && titleBottom > -windowHeight * 0.1;
            const isHiding = titleBottom < -windowHeight * 0.1 || titleTop > windowHeight * 0.9;

            if (isVisible) {
                title.style.transitionDelay = '0s';
                title.classList.add('visible');
                title.classList.remove('hiding');
            } else if (isHiding) {
                title.style.transitionDelay = '0s';
                title.classList.remove('visible');
                title.classList.add('hiding');
            }
        });

        // Group cards by their vertical position (row)
        const cardGroups = {};
        cards.forEach((card, index) => {
            const rect = card.getBoundingClientRect();
            const rowKey = Math.round(rect.top / 100) * 100;

            if (!cardGroups[rowKey]) {
                cardGroups[rowKey] = [];
            }
            cardGroups[rowKey].push({ card, index });
        });

        // Sort groups by vertical position
        const sortedGroups = Object.entries(cardGroups)
            .sort(([a], [b]) => Number(a) - Number(b))
            .map(([_, cards]) => cards);

        // Process each row
        sortedGroups.forEach((rowCards, rowIndex) => {
            rowCards.forEach(({ card, index }) => {
                const cardTop = card.getBoundingClientRect().top;
                const cardBottom = card.getBoundingClientRect().bottom;

                // Check if scrolling down (positive scroll delta)
                const isScrollingDown = scrollY > this.state.lastScrollY;

                if (isScrollingDown) {
                    // When scrolling down, show card when its top hits the trigger line
                    const isAeronauticsCard = card.classList.contains('aeronautics-card');
                    const currentTriggerLine = isAeronauticsCard ? aeronauticsTriggerLine : triggerLineDown;

                    if (cardTop < currentTriggerLine) {
                        const delay = rowIndex * 0.1;
                        card.style.transitionDelay = `${delay}s`;
                        card.classList.add('visible');
                        card.classList.remove('hiding');
                        this.setState(prevState => ({
                            visibleCards: new Set(prevState.visibleCards).add(index)
                        }));
                    }
                } else {
                    // When scrolling up, hide card when its bottom hits the bottom of viewport
                    if (cardBottom > triggerLineUp) {
                        if (!animateOneTimeOnly) {
                            card.style.transitionDelay = '0s';
                            card.classList.remove('visible');
                            card.classList.add('hiding');
                            this.setState(prevState => {
                                const newVisibleCards = new Set(prevState.visibleCards);
                                newVisibleCards.delete(index);
                                return { visibleCards: newVisibleCards };
                            });
                        }
                    }
                }
            });
        });

        // Update last scroll position
        this.setState({ lastScrollY: scrollY });
    }

    render() {
        return (
            <div style={{
                ...divStyle
            }} className="glass-background" id="experienceScroll">
                <div style={{}}>
                    <p className="section-title main-title" style={{
                        fontSize: '40px',
                        fontWeight: '800',
                        marginTop: '-10px',
                        textAlign: 'center',
                        color: '#1A202C',
                        userSelect: 'none',
                        marginBottom: '20px',
                        letterSpacing: '-0.5px'
                    }}>Experience</p>

                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div style={{
                            display: 'table', flex: '0 1 520px'
                        }}>
                            <div style={{ display: 'inline-block', width: '100%' }}>
                                <p style={{ textAlign: 'center', ...sectionStyle }}>Work</p>
                            </div>
                            {/* Citadel Securities */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginBottom: '0px', width: '100%', ...card
                            }}>
                                <img src={citsec} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} className="experience-image" />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Incoming Software Engineer Intern</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.citadelsecurities.com")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Citadel Securities</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun - Aug 2025 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            {/* Eric Schmidt & Sebastian Thrun Startup Program */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card
                            }}>
                                <img src={goldengatebridge} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} className="experience-image" />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Software Engineer</p>
                                    <p className="organization" style={{ textDecoration: 'none', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Eric Schmidt & Sebastian Thrun Startup Program</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>May 2024 - Sep 2024 <b>·</b> 5 months</p>
                                </div>
                            </div>
                            {/* GM */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card
                            }}>
                                <img src={gm} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} className="experience-image" />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Software Engineer Intern</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.gm.com")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>General Motors</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun - Aug, 2023 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card
                            }}>
                                <img src={lockheed} width={imageW} style={{ ...imageStyle, marginTop: '8px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '8px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>AI / ML Engineer Intern</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics/skunkworks.html")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Lockheed Martin | Skunk Works</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun - Aug 2022 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            {/* <div style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card,
                            }}>
                                <img src={ringle} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>English Tutor</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.ringleplus.com/en/student/landing/home")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px', color: '#4299E1' }}>Ringle</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun - Aug, 2022 <b>·</b> 3 months</p>
                                </div>
                            </div> */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card
                            }}>
                                <img src={lockheed} width={imageW} style={{ ...imageStyle, marginTop: '8px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '8px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>AI / ML Engineer Intern</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics/skunkworks.html")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Lockheed Martin | Skunk Works</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun - Jul 2021 <b>·</b> 2 months</p>
                                </div>
                            </div>
                            <div className="experience-card aeronautics-card" style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card
                            }}>
                                <img src={lockheed} width={imageW} style={{ ...imageStyle, marginTop: '8px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, marginTop: '8px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Software Engineer Intern (Backend)</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics.html")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Lockheed Martin | Aeronautics</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun 2020 - Jul 2020 <b>·</b> 2 months</p>
                                </div>
                            </div>
                        </div>

                        {/* The left margin that appears for the second column when window is minimnzied is because of this 40 px */}
                        <div style={{
                            display: 'table', marginLeft: '80px'
                        }} > </div>

                        <div style={{
                            display: 'table', flex: '0 1 520px',
                        }}>
                            <div style={{ display: 'inline-block', width: '100%' }}>
                                <p style={{ textAlign: 'center', ...sectionStyle }}>Research / Misc.</p>
                            </div>
                            {/* Princeton RL Lab */}
                            <div className="experience-card" style={{
                                display: 'inline-block', width: '100%', ...card
                            }}>
                                <img src={pni} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle', marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Undergrad Researcher</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://princeton-rl.github.io/")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Princeton Reinforcement Learning Lab</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Oct 2024 - Present <b>·</b> 7 months</p>
                                </div>
                            </div>
                            {/* PNI */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, width: '100%', ...card
                            }}>
                                <img src={pni} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle', marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Undergrad Researcher</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://pni.princeton.edu/")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Princeton Neuroscience Institute, Buschman Lab</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Feb - May 2023  <b>·</b> 4 months</p>
                                </div>
                            </div>
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, width: '100%', ...card
                            }}>
                                <svg width={imageW} height={imageW} style={{ ...imageStyle, marginTop: '6px' }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="#4299E1" />
                                </svg>
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle', marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Independent Researcher</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://ieeexplore.ieee.org/document/9701628")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Global Landslide Forecasting & Analysis (Paper)</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Oct 2020 - May 2021 <b>·</b> 8 months</p>
                                </div>
                            </div>
                            {/* AYSI */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, width: '100%', ...card
                            }}>
                                <img src={aysi} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle', marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>Science Fair Mentor</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://aysi.org")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Association for Young Scientists & Innovators (AYSI)</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Oct 2020 - Oct 2024 <b>·</b> 4 years</p>
                                </div>
                            </div>
                            {/* Ringle */}
                            <div className="experience-card" style={{
                                display: 'inline-block', marginTop: space, width: '100%', ...card
                            }}>
                                <img src={ringle} width={imageW} style={{ ...imageStyle, marginTop: '6px' }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle', marginTop: '6px' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '0px', marginBottom: '0px', color: '#2D3748', letterSpacing: '-0.3px' }}>English Tutor</p>
                                    <p className="organization organization-link" onClick={() => window.open("https://www.ringleplus.com/en/student/landing/home")} style={{ textDecoration: 'none', cursor: 'pointer', marginTop: '2px', fontSize: smaller, marginBottom: '0px' }}>Ringle</p>
                                    <p style={{ marginTop: '2px', fontSize: '17px', color: '#718096', marginBottom: '0px', letterSpacing: '0.2px' }}>Jun - Aug, 2022 <b>·</b> 3 months</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div >
        )
    }
}
const sectionStyle = {
    marginTop: '20px',
    fontSize: '28px',
    fontWeight: '700',
    color: '#2D3748',
    marginBottom: '20px',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '-10px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '60px',
        height: '4px',
        background: '#4299E1',
        borderRadius: '2px'
    }
}
const btnStyle = {
    background: '#f76565', //3b8cff
    marginTop: '10px',
    borderRadius: '15px',
    // border: '5px solid ' + colors.border,
    border: '0px solid',
    padding: '15px',
    fontSize: '22px',
    fontWeight: '500',
    display: 'inline-block',
    color: '#fff',
    cursor: 'pointer'
}
const numbers = {
    fontSize: '33px',
    fontWeight: '600',
    marginTop: '0px',
    color: '#008cff', // The more indigo type of blue: 3779f5
    // fontFamily: 'Josefin Sans'
    fontFamily: 'Poppins'
}
const category = {
    fontSize: '25px',
    marginTop: '-30px',
    color: '#222',
    // color: '#4d75a3',
}
// I love you Google
// https://1linelayouts.glitch.me/
// https://youtu.be/qm0IfG1GyZU
const container = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
}
const container2 = {
    ...container,
    marginTop: '-21px',
}
const box = {
    // flex: '1 1 250px', /*  Stretching: */
    flex: '0 1 300px', /*  No stretching: */
    margin: '5px'
}
const box2 = {
    // flex: '1 1 250px', /*  Stretching: */
    flex: '0 1 260px', /*  No stretching: */
    margin: '40px',
}
// the span for the links in the IJ Apps descriptions text
const ijL = {
    textDecoration: 'underline',
    color: '#0091ff',
    cursor: 'pointer',
}

const divStyle = {
    width: '100vw',
    paddingTop: '60px',
    paddingBottom: pB,
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle'
}
const imageStyle = {
    borderRadius: '12px',
    float: 'left',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)'
    }
}
export default Experience

// export default IJ
