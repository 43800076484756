import React, { Component } from 'react'
import NavigationBar from './components/NavigationBar'
import EmptyBar from './other/EmptyBar'
import Contact from './components/Contact'
import Footer from './components/Footer'
import About from './components/About'
import Awards from './components/Awards'
import Redirector2 from './other/Redirector2'
import Redirector from './other/Redirector'
import RedirectorGen from './other/RedirectorGen'
import Resume from './components/Resume'
import Home from './pages/Home'
import ContactPage from './pages/ContactPage'
import ProjectsPage from './pages/ProjectsPage'
import Talk2Me from './pages/Talk2Me'
// import Talk2Friend from './pages/Talk2Friend'
import Projects from './components/Projects'
import Test from './components/Test'
import Helper from './other/Helper'
import IJ from './components/Orig'
import { Document, Page } from 'react-pdf'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Resume from './Resume.pdf'
// import { CSSTransitionGroup } from 'react-transition-group'
// import PDFViewer from 'pdf-viewer-reactjs'
import list from './Resume.pdf'
import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';
import MobileNavBar from './components/MobileNavBar'
import Experience from './components/Experience'
import ScalingCRL from './pages/ScalingCRL'

const array = ["a", "b", "c"];
function test(props) {
  return <p>Tasdfasdfasdfest</p>
}
/* 
TODO:
 1. Get PDF to show
*/
class App extends Component {
  render() {
    return (
      <Router>
        {/* <NavigationBar /> */}
        <Switch >
          {/* Idea is to make a Page component for each of the pages. The page components consist of './components' */}
          <Route path="/" exact component={() => <Home />} />
          {/* <div > */}
          <Route path="/contact" exact component={() => <ContactPage />} />
          <Route path="/projects" exact component={() => <ProjectsPage />} />
          <Route path="/project" exact component={() => <ProjectsPage />} />
          {/* <Route path="/d" exact component={() => { list }} /> */}
          <Route path="/distinctions-and-skills" exact component={() => <Redirector2 />} />
          <Route path="/distinctions" exact component={() => <Redirector2 />} />
          <Route path="/distinction" exact component={() => <Redirector2 />} />
          {/* <Route path="/projects" exact component={() => <Redirector />} /> */}
          {/* <Route path="/project" exact component={() => <Redirector />} /> */}
          <Route path="/resume" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20Resume.pdf" />} />

          <Route path="/pess-cos597a" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Written%20Report.pdf" />} />
          <Route path="/pess-paper" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Written%20Report.pdf" />} />
          <Route path="/cos597a" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Written%20Report.pdf" />} />

          <Route path="/presentation-cos597a" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Final%20Project.pdf" />} />
          <Route path="/pess-presentation" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Final%20Project.pdf" />} />
          <Route path="/slides-cos597a" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Final%20Project.pdf" />} />
          <Route path="/pess-slides" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Final%20Project.pdf" />} />
          <Route path="/pess" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Ishaan%20Javali%20-%20PESS%20-%20COS%20597A%20Final%20Project.pdf" />} />

          {/* TODO: change research to go to page with research papers */}
          {/* <Route path="/landslide-research" exact component={() => <RedirectorGen link="https://ishaanjavali.me/GLAS%20Research%20Paper.pdf" />} /> */}
          {/* <Route path="/glas" exact component={() => <RedirectorGen link="https://ishaanjavali.me/GLAS%20Research%20Paper.pdf" />} /> */}
          <Route path="/landslide-research" exact component={() => <RedirectorGen link="https://ieeexplore.ieee.org/document/9701628" />} />
          <Route path="/glas" exact component={() => <RedirectorGen link="https://ieeexplore.ieee.org/document/9701628" />} />
          <Route path="/poster" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Abstract%20and%20Poster.pdf" />} />
          <Route path="/ijapps" exact component={() => <IJ />} />
          <Route path="/talk2me" exact component={() => <Talk2Me />} />
          <Route path="/a&i" exact component={() => <Helper />} />

          <Route path="/llm-numeracy-poster" exact component={() => <RedirectorGen link="https://ishaanjavali.me/LLM%20Numeracy%20Poster.pdf" />} />
          <Route path="/llm-numeracy-paper" exact component={() => <RedirectorGen link="https://ishaanjavali.me/LLM%20Numeracy%20Paper.pdf" />} />

          {/* Terminal RL Project Routes */}
          <Route path="/terminal-rl-slides" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Terminal%20AI%20RL%20Agent%20Slides.pdf" />} />
          <Route path="/terminal-rl" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Strategic%20Mastery%20in%20Terminal%2C%20A%20Novel%20Reinforcement%20Learning%20Approach%20to%20Tower%20Defense%20Gameplay.pdf" />} />
          <Route path="/terminal-rl-paper" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Strategic%20Mastery%20in%20Terminal%2C%20A%20Novel%20Reinforcement%20Learning%20Approach%20to%20Tower%20Defense%20Gameplay.pdf" />} />
          <Route path="/terminal-rl-poster" exact component={() => <RedirectorGen link="https://ishaanjavali.me/Terminal%20AI%20RL%20Poster.pdf" />} />
          {/* Note: For the video route, you'll need to replace VIDEO_URL with the actual URL */}
          <Route path="/terminal-rl-video" exact component={() => <RedirectorGen link="https://youtu.be/CyHIvS6qMIc?si=kQm6gtarUrSdv2oC" />} />

          {/* </div> */}

          <Route path="/about" exact component={() => <About />} />
          <Route path="/me" exact component={() => <About />} />
          <Route path="/scaling-crl" exact component={() => <ScalingCRL />} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    )
  }
}
// Magic lines below. The top 3
const divStyle = {
  // minHeight: '10em',
  display: 'table-cell',
  verticalAlign: 'middle'
  // display: 'flex', alignItems: 'center',
  // justifyContent: 'center'
}
export default App
// for the div that contains contact route
// style={{ height: '90vh', background: '#f00' }}